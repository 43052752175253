.main-banner .btn-primary {
  background-color: #567BF0 !important;
  box-shadow: 0 13px 27px 0 rgb(198 121 227 / 25%) !important;
}


.carrer-vr {
  font-size: 1em !important;
  color: #6a7695;
  margin-top: 1em;
}

.text-c {
  font-size: 1em !important;
  color: #5e2ced !important;
}

.a-color {
  text-decoration: none !important;
  color: #6084a4 !important;
}

.file {
  color: #567BF0 !important;
  text-decoration: none !important;
}