.login {
    background-color: rgb(255, 255, 255);
    height: 89vh;
    margin-top: 2em;
    margin-bottom: 2em;
    box-shadow: 0px 10px 31px -8px #000000CC;
    border-radius: 50px;
    overflow-x: hidden;
}


.logo-admin {
    width: 8em;
    margin-top: 1em;
    margin-left: 2em;
}

.right-img {
    padding: 2em;
    background-color: #054698;
    height: 89vh;

}

.right-img img {
    height: 30em;
    width: 30em;
    object-fit: contain;
}

.details-admin {
    padding: 3em;
}

.details-admin form {
    margin-top: 4em;
}

form input:focus {
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
    border-bottom: 1px solid #000 !important;
}

.form-control {
    border: none !important;
    border-bottom: 1px solid #000 !important;
    border-radius: 0px !important;
}

.heading {
    color: #054698;
    font-size: 3em;
    margin: 0;
}

.detail-p {
    text-align: start;
    text-transform: capitalize;
}

.login-data {
    background-color: #054698;
    padding: 1em 5em;
    color: #fff;
    border: none;
}

.text-error {
    color: red;
    font-size: .8em;
}

@media (max-width:992px) {
    .login {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        border-radius: 0px;
        height: 100vh;
    }

    .heading {
        font-size: 2em;
    }
}

.otp-data input {
    height: 40px;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    color: #1a181e;
    width: 40px !important;
    font-size: 1em;
    line-height: 38px;
    font-weight: 500;
    margin-right: 10px;
    padding: 11px 8px;
}

.field-label1 {
    align-items: center;
    font-size: 0.8em;
}

.heading-otp {
    font-size: 2.5em;
    font-weight: 700;
}

.otp-verfication-form .resend-otp-link {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
}

.anchor-1 {
    color: #146eb4;
    cursor: pointer;
    overflow: hidden;
}


.sidebar {
    background-color: #0093E9;
    background-image: linear-gradient(160deg, #0093E9 0%, #94c8f3 100%);
    color: #fff;
    height: 100vh;
    width: 230px;
    position: fixed;
    transition: all 0.5s;
}

.admin-container {
    position: relative;
    left: 230px;
    top: 5px;
    width: calc(100% - 230px) !important;
}

.top_section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 15px;
}

.sidebar-logo {
    width: 3em;
}

.link {
    display: flex !important;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
    text-decoration: none;
    margin: .5em 1em;
    border-radius: 4px;
}

.link.logout {
    position: absolute;
    bottom: 0;
    width: 87%;
}

.link:hover {
    background: #054698;
    color: #fff;
    transition: all 0.5s;
}

.link.active {
    background: #054698;
    color: #fff;
}

.icon,
.link_text {
    font-size: 1em;
    color: #fff !important;
}

.fa-sign-out {
    color: #fff;
    font-size: 1.5em
}

.top-detail {
    position: sticky;
    width: 100%;
    border-bottom: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .7em 2em;
}

.top-detail img {
    width: 3.5em;
    border: 1px solid #000;
    border-radius: 50%;
    margin-right: .5em;
}

.dashboard-detail {
    padding: 2em;
    background-color: rgba(236, 236, 236, 0.5);
}

.dashboard-cards {
    padding: 1em 1em;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 10px 31px -10px #ffffffcc;
    border-radius: 4px;
}

.dashboard-cards p {
    font-size: 1em;
    font-weight: 600;
    color: #353535cc;
}

.dashboard-cards h1 {
    margin-top: .2em;
    font-weight: 700;
    color: #337ab1 !important;
}

.resume-table h5 {
    margin-top: 1em;
    font-size: 2em;
}

.page-link:focus {
    outline: none !important;
    box-shadow: none !important;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
    flex: 1;
}

.page-item.active {
    background-color: transparent !important;
}

.page-link {
    border: none !important;
    border-radius: 0px !important;
    color: #000 !important;
    cursor: pointer;
    font-size: 12px;
}

.active>.page-link,
.page-link.active {
    background-color: #054698 !important;
    color: #fff !important;
}

.pre {
    border: none !important;
    background-color: transparent !important;
    font-size: 12px;
    margin-right: 1em;
    margin-left: 1em;
}

.pre:disabled {
    background-color: transparent !important;
}

.pre:hover {
    color: #141414;
}

.showing-page {
    position: relative;
}

.count {
    position: absolute;
    bottom: 50%;
}

.count-p {
    font-size: .8em;
    margin-top: 0px;
    margin-left: .6em;
}

.delete-resume {
    border: none;
    background-color: #0093E9;
    color: #fff;
    border-radius: 4px;
    padding: .5em 1em;
}

#completion {
    width: 20%;
    height: 20%;
    margin: auto;
    display: block;
}

@keyframes hideshow {
    0% {
        opacity: 0.2;
    }

    10% {
        opacity: 0.2;
    }

    15% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}

#cirkel {
    animation: hideshow 0.4s ease;
}

#check {
    animation: hideshow 0.4s ease;
}

#stars {
    animation: hideshow 1.0s ease;
    opacity: 0.9;
}


@keyframes hideshow {
    0% {
        transform: scale(0.2);
        transform-origin: initial;

    }

    100% {
        transform: scale(1.0);
        transform-origin: initial;
    }
}

@keyframes draaien {
    0% {
        transform: rotate(40deg);
        transform-origin: initial;

    }

    100% {
        transform: scale(0deg);
        transform-origin: initial;
    }
}

#check {
    animation: draaien 0.8s ease;
}


@keyframes transparant {
    0% {
        opacity: 0;

    }

    100% {
        opacity: 1;
    }
}

#check {
    animation: transparant 2s;
}

.close {
    border: 2px solid #000;
    color: #fff;
    background-color: #000;
    padding: 0em .5em;
}

.form-outline input:focus {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    border-bottom: 1px solid #000 !important;
}


.add-job {
    border: none;
    display: block;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    outline: none;
    overflow: hidden;
    position: relative;
    color: #fff;
    font-weight: 700;
    font-size: 15px;
    background-color: #222;
    padding: 13px 60px;
    border-radius: 4px;
    margin: 0 auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.20);
}

.add-job span {
    position: relative;
    z-index: 1;
}

.add-job:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 490%;
    width: 140%;
    background: #0093E9;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
    transform: translateX(-95%) translateY(-25%) rotate(45deg);
}

.add-job:hover:after {
    -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
    transform: translateX(-9%) translateY(-25%) rotate(45deg);
}

.add-job i {
    color: #fff !important;
    margin-left: 1em;
}

.single-box {
    border: 1px dashed #afbe78ad;
    padding: 30px 20px;
    position: relative;
    z-index: 1;
    border-radius: 5px;
    background: #ffffff;
    -webkit-box-shadow: 0 0 10px rgba(72, 69, 224, 0.1);
    box-shadow: 0 0 10px rgba(72, 69, 224, 0.1);
    -webkit-transition: 0.5s;
    transition: 0.5s;
}


.single-box h3 {
    font-size: 18px;
    margin-top: 25px;
    margin-bottom: 15px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-box::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 5px;
    height: 100%;
    background: linear-gradient(135deg, #567BF0 0%, #61CDF4 100%);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-box p {
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-box h6 {
    font-weight: 600;
    font-size: .9em;
}

.single-box:hover h6 {
    color: #fff;
}

.single-box:hover h3 {
    color: #fff;
}

.single-box p {
    margin: 0;
    font-size: .8em;
}

.view-more {
    border: none;
    color: #000;
    background-color: transparent;
    cursor: pointer;
}

.fa-arrow-right {
    margin-left: 1em;
    color: #000;
}

.view-more:focus {
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
}

.single-box:hover {
    -webkit-transform: translateY(-9px);
    transform: translateY(-9px);
    border-color: transparent;
}

.single-box:hover::before {
    opacity: 1;
    visibility: visible;
}


/* edit */
.edit {
    border: none;
    display: block;
    text-align: center;
    cursor: pointer;
    text-transform: capitalize;
    outline: none;
    overflow: hidden;
    position: relative;
    color: #fff;
    font-weight: 700;
    font-size: .9em;
    background-color: #222;
    padding: .4em 1em;
    border-radius: 4px;
    margin: 0 auto;
    margin-right: .5em;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.20);
}

.edit span {
    position: relative;
    z-index: 1;
}

.edit:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 490%;
    width: 140%;
    background: #fb8500;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
    transform: translateX(50%) translateY(-25%) rotate(45deg);
}

.edit:hover:after {
    -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
    transform: translateX(-9%) translateY(-25%) rotate(45deg);
}

/* delete */

.delete {
    border: none;
    display: block;
    text-align: center;
    cursor: pointer;
    text-transform: capitalize;
    outline: none;
    overflow: hidden;
    position: relative;
    color: #fff;
    font-weight: 700;
    font-size: .9em;
    background-color: #222;
    padding: .4em 1em;
    border-radius: 4px;
    margin: 0 auto;
    margin-right: .5em;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.20);
}

.delete span {
    position: relative;
    z-index: 1;
}

.delete:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 490%;
    width: 140%;
    background: #e63946;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
    transform: translateX(60%) translateY(-25%) rotate(45deg);
}

.delete:hover:after {
    -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
    transform: translateX(-9%) translateY(-25%) rotate(45deg);
}

form input::-webkit-outer-spin-button,
form input::-webkit-inner-spin-button {
    appearance: none !important;
    margin: 0;
}

button:disabled {
    background-color: rgb(114, 114, 114) !important;
}

/* Firefox */
form input[type=number] {
    appearance: none !important;
}


.form-control:focus {
    box-shadow: none !important;
    outline: none !important;
}

/* submit */

.save-add {
    border: none;
    display: block;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    outline: none;
    overflow: hidden;
    position: relative;
    color: #fff;
    font-weight: 700;
    font-size: .9em;
    background-color: #222;
    padding: 13px 60px;
    border-radius: 4px;
    margin: 0 auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.20);
}

.save-add span {
    position: relative;
    z-index: 1;
}

.save-add:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 490%;
    width: 140%;
    background: #054698;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
    transform: translateX(-95%) translateY(-25%) rotate(45deg);
}

.save-add:hover:after {
    -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
    transform: translateX(-9%) translateY(-25%) rotate(45deg);
}

/* loader */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100000000;
    backdrop-filter: blur(10px);
}

.overlay .overlayDoor:before,
.overlay .overlayDoor:after {
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;

    transition: 0.5s cubic-bezier(0.77, 0, 0.18, 1);
    transition-delay: 0.8s;
}

.overlay .overlayDoor:before {
    left: 0;
}

.overlay .overlayDoor:after {
    right: 0;
}

.overlay.loaded .overlayDoor:before {
    left: -50%;
}

.overlay.loaded .overlayDoor:after {
    right: -50%;
}

.overlay.loaded .overlayContent {
    opacity: 0;
    margin-top: -15px;
}

.overlay .overlayContent {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.5s cubic-bezier(0.77, 0, 0.18, 1);
}

.overlay .overlayContent .skip {
    display: block;
    width: 130px;
    text-align: center;
    margin: 50px auto 0;
    cursor: pointer;
    color: #fff;
    font-family: "Nunito";
    font-weight: 700;
    padding: 12px 0;
    border: 2px solid #fff;
    border-radius: 3px;
    transition: 0.2s ease;
}

.overlay .overlayContent .skip:hover {
    background: #ddd;
    color: #444;
    border-color: #ddd;
}

.loader {
    width: 128px;
    height: 128px;
    border: 3px solid #000;
    border-bottom: 3px solid transparent;
    border-radius: 50%;
    position: relative;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader .inner {
    width: 64px;
    height: 64px;
    border: 3px solid transparent;
    border-top: 3px solid #000;
    border-radius: 50%;
    -webkit-animation: spinInner 1s linear infinite;
    animation: spinInner 1s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes spinInner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-720deg);
    }
}

@keyframes spinInner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-720deg);
    }
}

.detail-job {
    padding: 2em;
}

.details h6 {
    font-weight: 600;
}

.icons i {
    color: #fff !important;
    font-size: 1.5em;
}

.form-control .search {
    margin-right: 1em;
}

.delete-data {
    background-color: #e63946;
    border: none !important;
    border-radius: 4px;
    color: #ffffff;
    padding: .4em .6em;
}