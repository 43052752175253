.icon-apply {
    margin-right: 1em;
}

.but {
    float: left;
    display: flex;
    align-items: center;
    padding: .5em 1em;
    border: none;
    background: none;
    color: inherit;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
}

.but:focus {
    outline: none;
}

.but>span {
    vertical-align: middle;
}

.button--webcoderskull {
    -webkit-transition: border-color 0.4s, color 0.4s;
    transition: border-color 0.4s, color 0.4s;
}

.button--webcoderskull::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #567BF0;
    box-shadow: 0 13px 27px 0 rgb(198 121 227 / 25%);
    z-index: -1;
    opacity: 0;
    -webkit-transform: scale3d(0.7, 1, 1);
    transform: scale3d(0.7, 1, 1);
    -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
    transition: transform 0.4s, opacity 0.4s;
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button--webcoderskull.button--round-s::before {
    border-radius: 2px;
}

.button--webcoderskull.button--inverted::before {
    background: #7986cb;
}

.button--webcoderskull,
.button--webcoderskull::before {
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button--webcoderskull:hover {
    color: #fff;
    border-color: #567BF0;
}

.button--webcoderskull.button--inverted:hover {
    color: #37474f;
    border-color: #fff;
}

.button--webcoderskull:hover::before {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.button {
    color: #37474f;
    border-color: #37474f;
}

/* Borders */

.button--border-thick {
    border: 2px solid;
    border-radius: 4px !important;
}

.button--text-medium {
    font-weight: 500;
}

.fa-whatsapp {
    font-size: 1.4em;
    margin-right: .5em;
    color: #000;
}

.fa-phone {
    font-size: 1.4em;
    margin-right: .5em;
    color: #000;
}

.button--webcoderskull:hover i {
    color: #fff;
}

/* wattsap */

.button--web {
    -webkit-transition: border-color 0.4s, color 0.4s;
    transition: border-color 0.4s, color 0.4s;
}

.button--web::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #469751;
    box-shadow: 0 13px 27px 0 rgb(198 121 227 / 25%);
    z-index: -1;
    opacity: 0;
    -webkit-transform: scale3d(0.7, 1, 1);
    transform: scale3d(0.7, 1, 1);
    -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
    transition: transform 0.4s, opacity 0.4s;
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button--web.button--round-s::before {
    border-radius: 2px;
}

.button--web.button--inverted::before {
    background: #469751;
}

.button--web,
.button--web::before {
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button--web:hover {
    color: #fff;
    border-color: #469751;
}

.button--web.button--inverted:hover {
    color: #37474f;
    border-color: #fff;
}

.button--web:hover::before {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.button--web:hover i {
    color: #fff;
}

/* hero apply */

.details-apply {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10em 1em 1em 1em;
}


.details-apply h3 {
    font-size: 3em;
}

.details-apply h3:nth-child(1) {
    color: #000;
}

.details-apply h3:nth-child(2) {
    color: #000;
}

.details-apply h3:nth-child(3) {
    color: #567BF0;
}

.details-apply p {
    margin-top: 1.5em;
    font-weight: 600;
}

.img-apply {
    padding: 5em;
}

@media (max-width:991px) {
    .details-apply {
        padding: 8em 0 0 0;
    }

    .details-apply h3 {
        font-size: 2em;
    }

    .img-apply {
        padding: 1em;
        margin-top: 4em;
    }
}

.job-title {
    font-weight: 600;
    font-size: 1.5em;
}

.single-box {
    border: 1px dashed #00000020;
    padding: 30px 20px;
    position: relative;
    z-index: 1;
    border-radius: 5px;
    background: #ffffff;
    -webkit-box-shadow: 0 0 10px rgba(72, 69, 224, 0.1);
    box-shadow: 0 0 10px rgba(72, 69, 224, 0.1);
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-box h6 {
    font-weight: 600;
    font-size: .9em;
}

.single-box:hover h6 {
    color: #fff;
}

.single-box p {
    margin: 0;
    font-size: .8em;
}

.view-more {
    border: none;
    color: #000;
    background-color: transparent;
    cursor: pointer;
}

.fa-arrow-right {
    margin-left: 1em;
    color: #000;
}

.view-more:focus {
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
}

.fa-arrow-left {
    margin-right: .7em;
    color: #000;
}

.details h6 {
    color: #000;
    font-weight: 600;
}

.details p {
    margin: 0;
}

@media (max-width:992px) {
    .details p {
        font-size: .8em;
    }
}

.fa-share {
    margin-left: 1em;
}

/* apply */
.button--webc {
    -webkit-transition: border-color 0.4s, color 0.4s;
    transition: border-color 0.4s, color 0.4s;
}

.button--webc::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1e1e1e;
    box-shadow: 0 13px 27px 0 rgb(198 121 227 / 25%);
    z-index: -1;
    opacity: 0;
    -webkit-transform: scale3d(0.7, 1, 1);
    transform: scale3d(0.7, 1, 1);
    -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
    transition: transform 0.4s, opacity 0.4s;
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button--webc.button--round-s::before {
    border-radius: 2px;
}

.button--web.button--inverted::before {
    background: #1e1e1e;
}

.button--webc,
.button--webc::before {
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button--webc:hover {
    color: #fff;
    border-color: #1e1e1e;
}

.button--webc.button--inverted:hover {
    color: #37474f;
    border-color: #fff;
}

.button--webc:hover::before {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.button--webc:hover i {
    color: #fff;
}


/* sidebar */
.sidebar-form {
    position: fixed;
    overflow-y: scroll;
    top: 0;
    right: 0;
    z-index: 99999;
    height: 100%;
    background-color: #fff;
}


@media (max-width:992px) {
    .sidebar-form {
        width: 100%;
    }
}

.sidebar-content {
    padding: 2em;
}

.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.8em;
    background-color: #567BF0;
}

.sidebar-header h3 {
    color: #fff;
}

.sidebar-header i {
    color: #fff;
    border: 2px solid #fff;
    padding: .7em;
    font-size: 1em;
}

.upload-button {
    width: 100%;
    height: 150px;
    background-color: #D4D3D34D;
    border: 1px dashed #000;
    position: relative;
    cursor: pointer;
}

.upload-button input[type="file"] {
    appearance: none !important;
    background-color: red;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.attach-file {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    pointer-events: none;
    transform: translate(-50%, -50%);
}

.attach-file h5 {
    color: #000;
    font-size: 1em;
}

.attach-file p {
    font-size: .7em;
}

.attach-file h6 {
    color: #567BF0;
    margin-top: .6em;
    text-align: center;
}


.text-error {
    color: red;
    font-size: .8em;
}

#completion {
    width: 20%;
    height: 20%;
    margin: auto;
    display: block;
}

@keyframes hideshow {
    0% {
        opacity: 0.2;
    }

    10% {
        opacity: 0.2;
    }

    15% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}

#cirkel {
    animation: hideshow 0.4s ease;
}

#check {
    animation: hideshow 0.4s ease;
}

#stars {
    animation: hideshow 1.0s ease;
    opacity: 0.9;
}


@keyframes hideshow {
    0% {
        transform: scale(0.2);
        transform-origin: initial;

    }

    100% {
        transform: scale(1.0);
        transform-origin: initial;
    }
}

@keyframes draaien {
    0% {
        transform: rotate(40deg);
        transform-origin: initial;

    }

    100% {
        transform: scale(0deg);
        transform-origin: initial;
    }
}

#check {
    animation: draaien 0.8s ease;
}


@keyframes transparant {
    0% {
        opacity: 0;

    }

    100% {
        opacity: 1;
    }
}

#check {
    animation: transparant 2s;
}

.details li {
    margin-top: .7em;
    margin-bottom: 1em;
}

.text-apply a {
    color: #000 !important;
}